import { ref } from 'vue'
import { useI18n } from '@ha/components-v3'

/* haToaster is a ref to component (in default layout) which expose .push method to show toasts */
/* @TODO: Remove this pattern and only use useNotifications to push toast in a array and show it in layout */
const haToaster = ref(null)

export default () => {
  const { i18n } = useI18n()

  const pushNotification = (toast) => {
    haToaster.value.push(toast)
  }

  const notifyError = (error, params) => {
    console.error(error)

    const status = error?.response?.status ?? 500
    const toast = {
      type: params?.type ?? 'danger',
      title: params?.title ?? i18n.t(`error.code.${status}.title`),
      body: params?.body ?? i18n.t(`error.code.${status}.message`),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifySuccess = (params) => {
    const toast = {
      type: params?.type ?? 'success',
      title: params?.title ?? i18n.t(`toastMessages.default.successTitle`),
      body: params?.body ?? i18n.t(`toastMessages.default.successBody`),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifyInfo = (params) => {
    const toast = {
      type: params?.type ?? 'info',
      title: params?.title ?? i18n.t(`toastMessages.default.successTitle`),
      body: params?.body ?? i18n.t(`toastMessages.default.successBody`),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifyWarning = (params) => {
    const toast = {
      type: params?.type ?? 'warning',
      title: params?.title ?? i18n.t(`toastMessages.default.warningTitle`),
      body: params?.body ?? i18n.t(`toastMessages.default.warningBody`),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  return {
    haToaster,
    notifyError,
    notifySuccess,
    notifyInfo,
    pushNotification,
    notifyWarning
  }
}
