import { defineStore } from 'pinia'
import { type Referrer } from '../domains/registration/types/association.interface'
import { ref } from 'vue'
import { useNuxtApp } from '#app'
import { useNotifications } from '#imports'

export default defineStore('storeReferrer', () => {
  const { $apiInternal } = useNuxtApp()
  const { notifyError } = useNotifications()

  const referrer = ref<Referrer | null>(null)
  const isLoading = ref(false)

  const fetchReferrer = async (referrerName: string): Promise<Referrer> => {
    isLoading.value = true

    try {
      const response = (await $apiInternal(`/auth-content/${referrerName}`, {
        method: 'GET'
      })) as unknown as Referrer

      referrer.value = response
      return referrer.value
    } catch (error) {
      notifyError(error)
      throw error
    } finally {
      isLoading.value = false
    }
  }

  return { referrer, isLoading, fetchReferrer }
})
